import dayjs from 'dayjs';

// Note: all properties here are evaluated at build time.
// Any dynamic values need to be set in the section initializing the redux
// store at application launch. See wherever isStoreInitialized is used.
export default {
  // Following property is used by the Front application to know whether the
  // store has been initialized by JS or not.
  isStoreInitialized: false,
  activeLayers: [],
  pinnedStations: [],
  legendPosition: 'map',
  showLegend: null,
  mapPadding: [0, 0, 0, 0],
  homeDrawerOpen: true,
  // Computing the date based on a dynamic values so static pages
  // generated every night show recent data.
  mainDate: dayjs(new Date()).format('YYYY-MM-DD'),
  highlightElement: null,
};
