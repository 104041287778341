import { QueryClient, QueryClientProvider } from 'react-query';
import React from 'react';

const wrapPageElement = ({ element }) => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      {element}
    </QueryClientProvider>
  );
};

export default wrapPageElement;
