import initialState from './initialState';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_STORE_INITIALIZED': {
      let initValueToSet = true;
      if (action.value !== undefined) {
        initValueToSet = Boolean(action.value);
      }

      return {
        ...state,
        isStoreInitialized: initValueToSet,
      };
    }

    case 'LAYER_TOGGLE': {
      const { activeLayers: currentActiveLayers } = state;
      let activeLayers = [...currentActiveLayers];
      action.layers.forEach(layer => {
        const show = (typeof action.force === 'boolean')
          ? action.force
          : !activeLayers.some(activeLayer => activeLayer.id === layer.id);

        if (show) {
          activeLayers.push(layer);
        } else {
          activeLayers = activeLayers.filter(activeLayer => activeLayer.id !== layer.id);
        }
      });
      const hydroLegendHandlers = [
        'station',
        'sub_management_unit',
        'hydrography',
      ];
      const showHydroLegend = activeLayers
        .some(layer => hydroLegendHandlers.some(type => type === layer.type));
      const showPrecipitationLegend = activeLayers.some(layer => layer.type === 'precipitation');
      const showLegend = showHydroLegend || showPrecipitationLegend;
      const applyPadding = state.pinnedStations.length
        || (showLegend && state.legendPosition === 'map');

      return {
        ...state,
        activeLayers,
        showLegend,
        showHydroLegend,
        showPrecipitationLegend,
      };
    }

    case 'LEGEND_PLACE_CHANGE': {
      const newLegendPosition = state.legendPosition === 'map' ? 'side' : 'map';

      const applyPadding = state.pinnedStations.length
        || (state.showLegend && newLegendPosition === 'map');

      return {
        ...state,
        legendPosition: newLegendPosition,
      };
    }

    case 'STATION_PIN': {
      const { stationCode } = action;
      if (!stationCode) { return state; }

      const pinned = new Set(state.pinnedStations);
      pinned.add(stationCode);
      const newState = { pinnedStations: Array.from(pinned) };

      return {
        ...state,
        ...newState,
      };
    }

    case 'STATION_UNPIN': {
      if (!action.stationCode) { return state; }

      const pinned = new Set(state.pinnedStations);
      pinned.delete(action.stationCode);

      const count = [...Array.from(pinned)].filter(Boolean).length;

      const applyPadding = count
        || (state.showLegend && state.legendPosition === 'map');

      return {
        ...state,
        pinnedStations: Array.from(pinned),
      };
    }

    case 'STATION_UNPIN_ALL': {
      const applyPadding = state.showLegend && state.legendPosition === 'map';
      return {
        ...state,
        pinnedStations: [],
      };
    }

    case 'MAP_UPD_VIEWPORT': {
      const { center, zoom } = action;
      const newState = {};

      if (center) {
        newState.mapCenter = center;
      }
      if (zoom) {
        newState.mapZoom = zoom;
      }
      return {
        ...state,
        ...newState,
      };
    }

    case 'HOME_DRAWER_TOGGLE': {
      const homeDrawerOpen = typeof action.force === 'undefined'
        ? !state.homeDrawerOpen
        : action.force;

      return {
        ...state,
        homeDrawerOpen,
      };
    }

    case 'DATE_SET': {
      if (!action.date) {
        return state;
      }

      if (typeof action.date !== 'string') {
        // eslint-disable-next-line no-console
        console.error(new Error('DATE_SET value should be a string (YYYY-MM-DD)'));
      }

      return {
        ...state,
        mainDate: action.date,
      };
    }

    case 'GEOFOCUS_HIGHLIGHT': {
      const { label, ID, optionType } = action;

      const highlightElement = { type: optionType, ID, label };

      return {
        ...state,
        highlightElement,
      };
    }

    case 'GEOFOCUS_HIDE': {
      const highlightElement = null;

      return {
        ...state,
        highlightElement,
      };
    }

    default:
      return state;
  }
};

export default reducer;
