// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-compare-js": () => import("./../../../src/pages/compare.js" /* webpackChunkName: "component---src-pages-compare-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-seuils-js": () => import("./../../../src/pages/seuils.js" /* webpackChunkName: "component---src-pages-seuils-js" */),
  "component---src-pages-stations-js": () => import("./../../../src/pages/stations.js" /* webpackChunkName: "component---src-pages-stations-js" */),
  "component---src-templates-simple-page-js": () => import("./../../../src/templates/SimplePage.js" /* webpackChunkName: "component---src-templates-simple-page-js" */),
  "component---src-templates-station-page-js": () => import("./../../../src/templates/StationPage.js" /* webpackChunkName: "component---src-templates-station-page-js" */)
}

