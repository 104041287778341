import { createTheme } from '@material-ui/core';

const defaultTheme = createTheme();

const theme = createTheme({
  palette: {
    primary: {
      main: '#4d6a7e',
    },
    secondary: {
      main: '#dd9646',
      contrastText: '#ffffff',
    },
    background: {
      default: '#ffffff',
    },
  },
  props: {
    // MuiButton: { size: 'small' },
    MuiFilledInput: { margin: 'dense' },
    MuiFormControl: { margin: 'dense' },
    MuiFormHelperText: { margin: 'dense' },
    MuiIconButton: { size: 'small' },
    MuiInputBase: { margin: 'dense' },
    MuiInputLabel: { margin: 'dense' },
    MuiListItem: { dense: true },
    MuiOutlinedInput: { margin: 'dense' },
    MuiFab: { size: 'small' },
    MuiTable: { size: 'small' },
    MuiTextField: { margin: 'dense' },
    MuiToolbar: { variant: 'dense' },
  },
  overrides: {
    MuiIconButton: {
      sizeSmall: {
        // Adjust spacing to reach minimal touch target hitbox
        marginLeft: 4,
        marginRight: 4,
        padding: 12,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        '& > :hover': {
          backgroundColor: '#37474f',
        },
      },
    },
  },
  typography: {
    fontSize: 14,
    h1: { fontSize: '3rem' },
    h2: { fontSize: '2.125rem' },
    h3: { fontSize: '1.5rem' },
    h4: { fontSize: '1.25rem' },
    h5: { fontSize: '1.125rem' },
    h6: { fontSize: '1rem' },
    caption: { color: '#616161' },
  },
  homeDrawer: {
    width: {
      main: 300,
      narrow: defaultTheme.spacing(7),
    },
  },
});

export default theme;
